import { Component, Prop, Vue } from 'vue-property-decorator';
import type { Users } from 'viewalk-types'

import users from '@/state/users';

@Component
export default class UserCard extends Vue {
  @Prop() id!: string;
  // actions = [
  //   {
  //     label: 'Invite to current session',
  //     icon: 'mdi-share',
  //     method: () => {
  //       console.log('Not yet implemented');
  //     },
  //   },
  //   {
  //     label: 'Unfriend',
  //     icon: 'mdi-account-off',
  //     method: () => {
  //       console.log('Not yet implemented');
  //     },
  //   },
  // ]
  get user(){
    return users.get(this.id);
  }
  get canAdd(){
    if (!users.current){
      return false;
    }
    return !users.current.friends.includes(this.id);
  }
  get canCancel(){
    if (!users.current){
      return false;
    }
    if (!users.current.friends.includes(this.id)){
      return false;
    }
    return !this.user.friends.includes(users.current._id);
  }
  get canRemove(){
    if (!users.current){
      return false;
    }
    if (!users.current.friends.includes(this.id)){
      return false;
    }
    return this.user.friends.includes(users.current._id);
  }
  add(){
    if (!users.current){
      return;
    }
    return users.update(users.current._id, {
      friendAdd: this.id,
    });
  }
  cancel(){
    if (!users.current){
      return;
    }
    return users.update(users.current._id, {
      friendCancel: this.id,
    });
  }
  remove(){
    if (!users.current){
      return;
    }
    return users.update(users.current._id, {
      friendRemove: this.id,
    });
  }
}