import { Component, Vue } from 'vue-property-decorator';
import WorldCard from '@/components/WorldCard.vue';
import WorldEvents from '@/components/WorldEvents.vue';
import events from '../state/events';
import worlds from '../state/worlds';
import Xp from '@/components/Xp.vue';
import DailyGoal from '@/components/DailyGoal.vue';

import { client } from '@/plugins/spacewalk';

class Version {
  major = 0;
  minor = 0;
  patch = 0;
  constructor(string: String){
    const split = string.split('.');
    this.major = parseInt(split[0]);
    this.minor = parseInt(split[1]);
    this.patch = parseInt(split[2]);
  }
  isGreaterThan(version: Version){
    if (this.major > version.major){
      return true;
    }
    if (this.major === version.major){
      if (this.minor > version.minor){
        return true;
      }
      if (this.minor === version.minor){
        if (this.patch > version.patch){
          return true;
        }
      }
    }
    return false;
  }
}

@Component({
  components: {
    WorldCard,
    WorldEvents,
    Xp,
    DailyGoal,
  },
})
export default class Home extends Vue {
  requireSinglePlayer = false;
  requireMultiPlayer = false;
  showFilters = false;
  updateUrl = 'https://apps.apple.com/app/apple-store/id1627194098?pt=124531720&ct=update&mt=8';
  onClickSinglePlayer(){
    this.requireSinglePlayer = !this.requireSinglePlayer;
    if (this.requireSinglePlayer){
      this.requireMultiPlayer = false;
    }
  }
  onClickMultiPlayer(){
    this.requireMultiPlayer = !this.requireMultiPlayer;
    if (this.requireMultiPlayer){
      this.requireSinglePlayer = false;
    }
  }
  get outdated(){
    const current = new Version(client.reactive.version);
    const latest = new Version(client.reactive.latestIosVersion);
    return latest.isGreaterThan(current);
    return client.reactive.buildId < client.reactive.latestBuildId;
  }
  get events(){
    return events.all;
  }
  get worlds(){
    return worlds.all.filter(world => {
      if (!world.loaded){
        return false;
      }
      if (this.requireSinglePlayer && !world.singlePlayer){
        return false;
      }
      if (this.requireMultiPlayer && !world.multiPlayer){
        return false;
      }
      return true;
    }).sort((a, b) => {
      if (a._id === client.reactive.settings.onboardingMap){
        return -1;
      }
      if (b._id === client.reactive.settings.onboardingMap){
        return 1;
      }
      const aFeaturedAt = a.featuredAt ? a.featuredAt.valueOf() : 0;
      const bFeaturedAt = b.featuredAt ? b.featuredAt.valueOf() : 0;
      if (aFeaturedAt != bFeaturedAt){
        return bFeaturedAt - aFeaturedAt;
      }
      const aNumberOfEvents = events.getByWorld(a._id).length;
      const bNumberOfEvents = events.getByWorld(b._id).length;
      if (aNumberOfEvents !== bNumberOfEvents){
        // console.log(`${a.title} and ${b.title} has different number of events: ${aNumberOfEvents} v ${bNumberOfEvents}`);
        return bNumberOfEvents - aNumberOfEvents;
      }
      const aIsYourOwn = a.createdBy === client.reactive.auth._id;
      const bIsYourOwn = b.createdBy === client.reactive.auth._id;
      if (aIsYourOwn !== bIsYourOwn){
        return Number(bIsYourOwn) - Number(aIsYourOwn);
      }
      const aUpdatedAt = a.updatedAt ? a.updatedAt.valueOf() : 0;
      const bUpdatedAt = b.updatedAt ? b.updatedAt.valueOf() : 0;
      return bUpdatedAt - aUpdatedAt;
    });
  }
  eventCodeErrors = '';
  onEventCode(input: string){
    if (!/\d{5}/.test(input)){
      return;
    }
    client.native.sessions.readInvite(input).then(invite => {
      this.$router.push({
        name: 'Event',
        params: {
          id: invite.sessionId,
        }
      })
    }).catch(error => {
      this.eventCodeErrors = `Invalid code: ${input}`;
    })
  }
}