import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import worlds, { World } from '../state/worlds';

@Component
export default class WorldPicker extends Vue {
  @Prop({required: true}) value!: string;
  @Prop({default: false}) disabled!: boolean;
  @Prop({default: false}) loading!: boolean;
  get worlds(){
    return worlds.all;
  }
  worldFilter (item: World, queryText: string, itemText: string) {
    if (item.title.includes(queryText)){
      return true;
    }
    return false;
  }
  onInput(value: string){
    this.$emit('input', value);
    (this.$refs.autocomplete as HTMLElement).blur();
  }
  mounted(){
    worlds.refresh();
  }
} 