import { Component, Vue } from 'vue-property-decorator';
import EventComponent from '@/components/Event.vue'; // @ is an alias to /src

import events from '../state/events';

@Component({
  components: {
    EventComponent,
  },
})
export default class EventView extends Vue {
  get event(){
    return events.get(this.$route.params.id);
  }
}