import Vue from 'vue';
import type { Maps } from 'viewalk-types';
import { client } from '@/plugins/spacewalk';

export interface World extends Required<Maps.Map> {
  loaded: boolean;
  // img: string;
}
type WorldWithId = Required<Pick<World, '_id'>> & Partial<World>;

const assert = function(_id: string): World{
  if (!_id){
    console.error(_id);
    throw new Error('Invalid id');
  }
  let entry = vm.all.find(exp => exp._id === _id);
  if (!entry){
    entry =  {
      loaded: false,
      _id,
      title: '',
      createdBy: '',
      createdAt: null,
      updatedAt: null,
      deletedAt: null,
      public: false,
      pakId: '',
      img: '',
      singlePlayer: false,
      multiPlayer: false,
      admins: [],
      contributors: [],
      official: false,
      apiLevel: 0,
      featuredAt: null,
      description: '',
    }
    vm.all.push(entry);
  }
  return entry;
}
interface State {
	all: World[];
  current: string;
}
const vm = new Vue<State>({
  data: {
    all: [],
    current: '',
  }
})

export default {
  get vm(){
    return vm;
  },
  insertOne(input: WorldWithId){
    const entry = assert(input._id);
    for (const key of Object.keys(input)){
      const value = (input as any)[key];
      if (key === 'img' && typeof value === 'string'){
        entry.img = value;
        if (!entry.img.startsWith('https://')){
          entry.img = `https://assets.spacewalk.no/maps/${entry._id}/${entry.img}`;
        }
      } else if (['featuredAt', 'updatedAt', 'createdAt', 'deletedAt'].includes(key) && typeof(value) === 'string'){
        (entry as any)[key] = new Date(value);
      } else {
        (entry as any)[key] = value;
      }
    }
    entry.loaded = true;
    return entry;
  },
  insert(inputs: WorldWithId[]){
    const entries: World[] = [];
    for (const input of inputs){
      entries.push(this.insertOne(input));
    }
    return entries;
  },
  get(_id: string){
    if (!_id){
      throw new Error('No id provided');
    }
    const entry = assert(_id);
    if (!entry.loaded && _id !== 'none'){
      client.native.assets.mapRead(_id).then(input => {
        this.insertOne(input).loaded = true;
      });
    }
    return entry;
  },
	get all(){
		return vm.all;
	},
  get current(){
    return vm.current;
  },
  set current(_id: string){
    vm.current = _id;
  },
  refresh(){
    return client.native.assets.refreshMaps().then(() => {
      this.insert(client.native.assets.mapCache).forEach(world => {
        world.loaded = true;
      });
    });
  },
  getByPakId(pakId: string){
    return vm.all.find(world => {
      return world.pakId === pakId;
    });
  },
  update(_id: string, updates: Partial<World>){
    return client.native.assets.mapUpdate(_id, updates).then(res => {
      this.insertOne(res);
    });
  }
}