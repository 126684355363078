import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router'

// import Onboarding from '@/components/Onboarding/index.vue'
import users from './state/users';
import events from './state/events';

// OnboardingComponents
import OnboardingName from '@/components/Onboarding/Name.vue';
// import OnboardingMap from '@/components/Onboarding/OnboardingMap.vue';

// set SpacewalkWebView Endpoint http://192.168.31.104:8080
@Component({
  components: {
    OnboardingName,
    // OnboardingMap,
  }
})
export default class App extends Vue {
  drawer = false;
  get auth(){
    return this.$spacewalk.reactive.auth;
  }
  get profile(){
    if (!this.auth._id){
      return null;
    }
    return users.get(this.auth._id);
  }
  get events(){
    return events;
  }
  // background = 'linear-gradient(-12deg, rgba(0, 0, 0, 0) 20%, rgba(1, 232, 239, .3), rgba(0, 0, 0, 0) 80%);';
  get isTopLevelView(){
    if (!this.$route.name){
      return false;
    }
    return [
      'Home',
      'Events',
      'Worlds',
      'Friends',
      'Playing',
    ].includes(this.$route.name);
    // const slashCount = (this.$route.path.match(/\//g) || []).length;
    // return slashCount <= 1;
  }
  get avatar(){
    return '';
    // return users.current.img;
  }
  get initials(){
    if (!users.current){
      return '';
    }
    return users.initials(users.current);
    // return users.current.img;
  }
  transitionName = '';
  @Watch('$route')
  onRouteChange(to: Route, from: Route){
    const toDepth = to.path.split('/').length;
    const fromDepth = from.path.split('/').length;
    this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
  }
  back(event: Event){
    if (this.isTopLevelView){
      this.$spacewalk.ue.Hide();
      // event.preventDefault();
      // event.stopPropagation();
    } else {
      this.$router.back();
    }
  }
  get onboardingNamePending(){
    return !!this.profile && !this.profile.displayName;
  }
  get onboardingMapPending(){
    return !!this.profile && !this.profile.achievements.viewalking101 && !!this.$spacewalk.reactive.buildId;
  }
  // get onboardingComponents(){
  //   console.log('get onboardingComponents()')
  //   const components: Vue.Component[] = [];
  //   if (this.profile){
  //     console.log('this.profile')
  //     if (!this.profile.displayName){
  //       components.push(Name);
  //     }
  //     if (!this.profile.achievements.viewalking101){
  //       if (this.$spacewalk.reactive.buildId){
  //         components.push(OnboardingMap);
  //       }
  //     }
  //   } else {
  //     console.log('!this.profile')
  //   }
  //   console.log('components', components);
  //   return components;
  // }
  get onboardingPending(){
    // console.log('profile:', this.profile);
    return this.onboardingNamePending;
    return this.onboardingNamePending || this.onboardingMapPending;
    // return !!this.onboardingComponents.length;
  }
  step = 0;
}