import { Component, Prop, Vue } from 'vue-property-decorator';
import { DateTime } from 'luxon';

@Component
export default class CalendarDate extends Vue {
  @Prop({required: true}) value!: string;
  get dateTime(){
    return DateTime.fromISO(this.value);
  }
  get weekday(){
    return this.dateTime.toLocaleString({
      weekday: 'short',
    });
  }
  get date(){
    const now = DateTime.now();
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'short',
    };
    if (this.dateTime.diff(now).years > 0){
      options.year = 'numeric';
    }
    return this.dateTime.toLocaleString(options);
  }
}