import { Component, Vue } from 'vue-property-decorator';
import WorldCard from '@/components/WorldCard.vue'; // @ is an alias to /src
import worlds from '../state/worlds';

@Component({
  components: {
    WorldCard,
  },
})
export default class Worlds extends Vue {
  get worlds(){
    return worlds.all.filter(world => {
      if (!world.loaded){
        console.log('!world.loaded');
        return false;
      }
      if (world.deletedAt){
        console.log('world.deletedAt');
        return false;
      }
      if (this.requireSinglePlayer && !world.singlePlayer){
        return false;
      }
      if (this.requireMultiPlayer && !world.multiPlayer){
        return false;
      }
      if (this.requireOfficial && world.createdBy !== 'official'){
        return false;
      }
      if (this.requireCommunity && world.createdBy === 'official'){
        return false;
      }
      return true;
    });
  }
  mounted(){
    worlds.refresh();
  }
  requireSinglePlayer = false;
  requireMultiPlayer = false;
  onClickSinglePlayer(){
    this.requireSinglePlayer = !this.requireSinglePlayer;
    if (this.requireSinglePlayer){
      this.requireMultiPlayer = false;
    }
  }
  onClickMultiPlayer(){
    this.requireMultiPlayer = !this.requireMultiPlayer;
    if (this.requireMultiPlayer){
      this.requireSinglePlayer = false;
    }
  }
  requireOfficial = false;
  requireCommunity = false;
  onClickOfficial(){
    this.requireOfficial = !this.requireOfficial;
    if (this.requireOfficial){
      this.requireCommunity = false;
    }
  }
  onClickCommunity(){
    this.requireCommunity = !this.requireCommunity;
    if (this.requireCommunity){
      this.requireOfficial = false;
    }
  }
}