import { Component, Prop, Vue } from 'vue-property-decorator';

import Profile from '@/components/Profile.vue'
import users from '@/state/users'

@Component({
  components: {
    Profile,
  }
})
export default class Account extends Vue {
  menu = [
    // {
    //   id: 'profile',
    //   label: 'Profile',
    //   icon: 'mdi-account',
    //   route: {name: 'Account'},
    // },
    {
      id: 'settings',
      label: 'Settings',
      icon: 'mdi-cog',
      route: {name: 'Settings'},
    },
    // {
    //   id: 'subscription',
    //   label: 'Subscription',
    //   icon: 'mdi-currency-usd',
    //   route: {name: 'Subscription'},
    //   subtitle: 'Premium trial',
    // },
    {
      id: 'help',
      label: 'Help',
      icon: 'mdi-help',
      route: {name: 'Help'},
    },
  ]
  signOut(){
    return this.$spacewalk.signOut();
  }
}