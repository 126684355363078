// Workaround for iOS webview returning a promise object rather than just the value.

import type Bridge from './Bridge';

interface Proxy extends Bridge {
  bridge?: Bridge;
}
export function fixPromise(input: any | Promise<any>): any{
  if (input && Object.keys(input).includes('ReturnValue')){
    return input.ReturnValue;
  }
  return input;
};
const proxy: Proxy = {
  get bridge(){
    return window.ue && window.ue.bridge;
  },
  Hide:    function(...args){
    if (!this.bridge) return Promise.resolve(undefined);
    return this.bridge.
    Hide(...args)
    .then(fixPromise);
  },
  HasPak: function(...args){
    if (!this.bridge) return Promise.resolve(undefined);
    return this.bridge.
    HasPak(...args)
    .then(fixPromise);
  },
  PreparePak: function(...args){
    if (!this.bridge) return Promise.resolve(undefined);
    return this.bridge.
    PreparePak(...args)
    .then(fixPromise);
  },
  DownloadPak: function(...args){
    if (!this.bridge) return Promise.resolve(undefined);
    return this.bridge.
    DownloadPak(...args)
    .then(fixPromise);
  },
  Host: function(...args){
    if (!this.bridge) return Promise.resolve(undefined);
    return this.bridge.
    Host(...args)
    .then(fixPromise);
  },
  Travel: function(...args){
    if (!this.bridge) return Promise.resolve(undefined);
    return this.bridge.
    Travel(...args)
    .then(fixPromise);
  },
  Leave: function(...args){
    if (!this.bridge) return Promise.resolve(undefined);
    return this.bridge.
    Leave(...args)
    .then(fixPromise);
  },
  GetRefreshToken: function(...args){
    if (!this.bridge) return Promise.resolve(undefined);
    return this.bridge.
    GetRefreshToken(...args)
    .then(fixPromise);
  },
  GetBuildId: function(...args){
    if (!this.bridge) return Promise.resolve(undefined);
    return this.bridge.
    GetBuildId(...args)
    .then(fixPromise);
  },
  GetVersion: function(...args){
    if (!this.bridge) return Promise.resolve(undefined);
    return this.bridge.
    GetVersion(...args)
    .then(fixPromise);
  },
  GetSupportedFeatures: function(...args){
    if (!this.bridge) return Promise.resolve(undefined);
    return this.bridge.
    GetSupportedFeatures(...args)
    .then(fixPromise);
  },
  PlaySolo: function(...args){
    if (!this.bridge) return Promise.resolve(undefined);
    return this.bridge.
    PlaySolo(...args)
    .then(fixPromise);
  },
  MoveEvent: function(...args){
    if (!this.bridge) return Promise.resolve(undefined);
    return this.bridge.
    MoveEvent(...args)
    .then(fixPromise);
  },
  GetCurrentMapId: function(...args){
    if (!this.bridge) return Promise.resolve(undefined);
    return this.bridge.
    GetCurrentMapId(...args)
    .then(fixPromise);
  },
  GetCurrentSessionId: function(...args){
    if (!this.bridge) return Promise.resolve(undefined);
    return this.bridge.
    GetCurrentSessionId(...args)
    .then(fixPromise);
  },
  GetIP: function(...args){
    if (!this.bridge) return Promise.resolve(undefined);
    return this.bridge.
    GetIP(...args)
    .then(fixPromise);
  },
  GetPort: function(...args){
    if (!this.bridge) return Promise.resolve(undefined);
    return this.bridge.
    GetPort(...args)
    .then(fixPromise);
  },
  SetKeyboxId: function(...args){
    if (!this.bridge) return Promise.resolve(undefined);
    return this.bridge.
    SetKeyboxId(...args)
    .then(fixPromise);
  },
  SignOut: function(...args){
    if (!this.bridge) return Promise.resolve(undefined);
    return this.bridge.
    SignOut(...args)
    .then(fixPromise);
  },
  SetWebViewReady: function(...args){
    if (!this.bridge) return Promise.resolve(undefined);
    return this.bridge.
    SetWebViewReady(...args)
    .then(fixPromise);
  },
  UpdateCachedUser: function(...args){
    if (!this.bridge) return Promise.resolve(undefined);
    return this.bridge.
    UpdateCachedUser(...args)
    .then(fixPromise);
  },
  GetAnalyticsSessionStartedAt: function(...args){
    if (!this.bridge) return Promise.resolve(undefined);
    return this.bridge.
    GetAnalyticsSessionStartedAt(...args)
    .then(fixPromise);
  },
  GetPlatform: function(...args){
    if (!this.bridge) return Promise.resolve(undefined);
    return this.bridge.
    GetPlatform(...args)
    .then(fixPromise);
  },
}
export default proxy;