import { Component, Vue, Watch } from 'vue-property-decorator';

import events from '../state/events';
import HostEventButton from '@/components/HostEventButton.vue';
import EventComponent from '@/components/Event.vue'; // @ is an alias to /src

@Component({
  components: {
    EventComponent,
    HostEventButton,
  },
})
export default class Playing extends Vue {
  get events(){
    return events;
  }
  // @Watch('events.current')
  // onCurrentEventChange(to: string, from: string){
  //   if (events.current === ''){
  //       this.$router.replace({
  //         name: 'Event',
  //         params: {
  //           id: from,
  //         }
  //       });
  //   }
  // }
  get event(){
    if (!events.current){
      return null;
    }
    return events.get(events.current);
  }
}