import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

// import { VTextField } from 'vuetify/lib';

@Component({
  components: {
    // WorldPicker,
  }
})
export default class EventCode extends Vue {
  @Prop() value!: string;
  copyToClipboardSuccess = false;
  copyToClipboardError = false;
  successMessages = '';
  copyToClipboard(){
    const inputs = this.$el.getElementsByTagName('input');
    if (inputs.length){
      inputs[0].select();
      inputs[0].setSelectionRange(0, 99999); /* For mobile devices */
      navigator.clipboard.writeText(inputs[0].value);
      this.successMessages = 'Copied to clipboard';
      inputs[0].setSelectionRange(0, 0);
      this.copyToClipboardSuccess = true;
      return;
    }
    this.copyToClipboardError = true;
  }
}