import { Component, Vue, Watch } from 'vue-property-decorator';

import { client } from '@/plugins/spacewalk';
import TextFieldBuffered from '@/components/TextFieldBuffered.vue';
import rules from '@/functions/rules';
import users from '@/state/users';
import worlds, { World } from '@/state/worlds';
import packages from '@/state/packages';

@Component({
  components: {
    TextFieldBuffered,
    // WorldEvents,
    // WelcomeBack,
  },
})
export default class OnboardingName extends Vue {
  beforeMount(){
    this.$spacewalk.addHideDelayer(this);
  }
  mounted(){
    console.log('Name::mounted');
  }
  beforeDestroy(){
    this.$spacewalk.removeHideDelayer(this);
  }

  get auth(){
    return this.$spacewalk.reactive.auth;
  }
  get profile(){
    if (!this.auth._id){
      return null;
    }
    return users.get(this.auth._id);
  }
  loading = false;
  displayName = {
    rules: [
      rules.notEmpty,
    ],
    value: (this.profile && this.profile.displayName) || '',
  }
  @Watch('profile.displayName')
  onDisplayNameChange(){
    console.log('onDisplayNameChange');
    if (this.profile && this.profile.displayName && !this.displayName.value){
      this.displayName.value = this.profile.displayName;
    }
  }
  // saveDisplayName(value: string){
  //   if (!this.auth._id){
  //     return;
  //   }
  //   this.loading = true;
  //   users.update(this.auth._id, {
  //     displayName: value,
  //   }).then(user=>{
  //     this.loading = false;
  //     this.$spacewalk.ue.UpdateCachedUser(JSON.stringify(user));
  //   })
  // }
  error = '';
  formValid = false;
  async submit(){
    if (!this.auth._id){
      return;
    }
    this.$analytics.event('profile.update', {
      displayName: true,
    });
    this.loading = true;
    return users.update(this.auth._id, {
      displayName: this.displayName.value,
    }).then(user=>{
      this.$spacewalk.ue.UpdateCachedUser(JSON.stringify(user));
    }).catch(error => {
      this.error = error;
      console.error(error);
    }).finally(()=>{
      this.loading = false;
    });
    
  }
}