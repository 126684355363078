import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Playing from '../views/Playing.vue'
import Events from '../views/Events.vue'
import EventView from '../views/Event.vue'
import EventsCreate from '../views/EventsCreate.vue'
import WorldView from '../views/World.vue'
import Worlds from '../views/Worlds.vue'
import Friends from '../views/Friends.vue'
import UserView from '../views/User.vue'
import Account from '../views/Account.vue'

Vue.use(VueRouter)

export const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/playing',
    name: 'Playing',
    component: Playing
  },
  {
    path: '/events/create/:world?',
    name: 'EventsCreate',
    component: EventsCreate,
    meta: {
      title: 'Host event',
    },
  },
  {
    path: '/events/:id',
    name: 'Event',
    component: EventView,
  },
  {
    path: '/events',
    name: 'Events',
    component: Events
  },
  {
    path: '/worlds/:id',
    name: 'World',
    component: WorldView,
  },
  {
    path: '/worlds',
    name: 'Worlds',
    component: Worlds
  },
  {
    path: '/friends',
    name: 'Friends',
    component: Friends
  },
  {
    path: '/users/:id',
    name: 'User',
    component: UserView,
  },
  {
    path: '/account',
    name: 'Account',
    component: Account
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
