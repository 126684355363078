import { Component, Vue } from 'vue-property-decorator';
import UserCard from '@/components/UserCard.vue';
import users from '../state/users';

@Component({
  components: {
    UserCard,
  },
})
export default class Friends extends Vue {
  filter: string | null = '';
  get friends(){
    return users.friends;
    // return users.all.filter(user => user._id !== this.$spacewalk.reactive.auth._id).filter(user => {
    //   if (this.filter){
    //     const searchable = user.displayName.toLocaleLowerCase();
    //     for (const word of this.filter.split(/\s+/)){
    //       if (!searchable.includes(word.toLocaleLowerCase())){
    //         return false;
    //       }
    //     }
    //   }
    //   return true;
    // });
  }
}