import { Component, Prop, Vue } from 'vue-property-decorator';
import achievements from '../state/achievements';
import { XpEntry } from '@/state/xp'
// import AchievementCard from '@/components/AchievementCard.vue';

@Component
export default class XpEntryComponent extends Vue {
  @Prop() entry!: XpEntry;
  dateFormatShort = {
    hour: '2-digit',
    minute: '2-digit',
    weekday: 'long',
    month: 'short',
    day: 'numeric',
  }
  dateFormatLong = {
    hour: '2-digit',
    minute: '2-digit',
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }
  get achievement(){
    if (!this.entry.achievement){
      return null;
    }
    return achievements.get(this.entry.achievement);
  }
  get title(){
    if (!this.achievement){
      return this.entry.achievement;
    }
    return this.achievement.title || this.entry.achievement;
  }
}