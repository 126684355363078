import { Component, Vue, Prop } from 'vue-property-decorator';
import type { Xp } from 'viewalk-types'

import XpProgress from '@/components/Xp.vue';
import XpEntry from '@/components/XpEntry.vue';
import xp from '../state/xp';
import users from '../state/users';
import * as human from '@/functions/human';


@Component({
  components: {
    XpProgress,
    XpEntry,
  },
})
export default class XpDetails extends Vue {
  @Prop() userId?: string;
  get user(){
    const userId = this.userId ? this.userId : users.current?._id;
    if (!userId){
      return null;
    }
    return users.get(userId);
  }
  get journal(){
    if (!this.user){
      return [];
    }
    return xp.byUser(this.user._id);
  }
  mounted(){
    // xp.refresh();
    // this.$nextTick(()=>{
    //   document.body.style.background = 'fixed linear-gradient(-12deg, rgba(0, 0, 0, 0) 20%, rgba(1, 232, 239, .2), rgba(0, 0, 0, 0) 80%)';
    // });
  }
  get totalXp(){
    return this.user?.xp || 0;
  }
  get walked(){
    return human.distance(this.user?.walked || 0);
  }
  get moveTime(){
    return human.duration(this.user?.moveTime || 0);
  }
  get playedSince(){
    if (!this.user?.playedSince){
      return '-';
    }
    return this.user?.playedSince.toLocaleDateString('en-US', {day: 'numeric', month: 'long', year: 'numeric'});
  }
  get showCheat(){
    return location.hostname !== 'bob.ui.viewalk.com' &&  new Date().toISOString().substr(0, 10) <= '2023-03-31';
  }
  cheat(){
    const client = this.$spacewalk.native;
    client.log.enableTick(10);
      client.log.accumulationMaxSeconds = 1;
      let count = 0;
      const state: {interval?: any} = {};
      state.interval = setInterval(()=>{
        count += 1;
        client.log.accumulate('walked', 100);
        if (count === 5){
          client.users.once('user', user => {
            console.log(user);
            // assert.equal(user.walked, 20, 'user.walked is wrong');
            // assert.equal(Math.round(user.moveTime || 0), 2, 'user.moveTime is wrong');
            // assert.equal(user.xp, 22);
            // resolve(true);
          });
          client.log.disableTick();
          clearInterval(state.interval!);
        }
      }, 1000);
  }
}