import { Component, Prop, Vue } from 'vue-property-decorator';
import type { World } from '../state/worlds';
import worlds from '../state/worlds';
import packages from '../state/packages';
import events, {SpacewalkEvent} from '../state/events';
import users from '../state/users';
import * as human from '@/functions/human';
import { client } from '@/plugins/spacewalk';
import DownloadProgress from '@/components/DownloadProgress.vue';
import TextFieldBuffered from '@/components/TextFieldBuffered.vue';

@Component({
  components: {
    DownloadProgress,
    TextFieldBuffered,
  },
})
export default class WorldComponent extends Vue {
  @Prop({required: true}) world!: World;
  mounted(){
    // worlds.get(this.world._id);
  }
  share(){
    return navigator.share({
      title: this.world.title,
      text: `Play ${this.world.title} on Viewalk!`,
      url: `https://api.spacewalk.no/worlds/og/${this.world._id}`,
    });
  }
  get events(){
    return events.getByWorld(this.world._id)
    // .filter(event => event._id !== events.current);
  }
  get isCurrentEventAdmin(){
    return events.isCurrentAdmin;
  }
  eventSubtitle(event: SpacewalkEvent){
    if (!event.startsAt && !event.endsAt){
      return 'Happening now.';
    }
    let subtitle = '';
    if (event.startsAt){
      subtitle = human.date(event.startsAt);
    }
    if (event.endsAt){
      if (subtitle){
        subtitle += ' - ';
      }
      subtitle += human.date(event.endsAt);
    }
    return subtitle;
  }
  getEventHost(event: SpacewalkEvent){
    if (!event.createdBy){
      return null;
    }
    return users.get(event.createdBy);
  }
  waitingToPlaySolo = false;
  playSolo(){
    this.waitingToPlaySolo = true;
    const pak = packages.get(this.world.pakId);
    packages.vm.$watch('current', pakId => {
      if (pakId === this.world.pakId){
        this.waitingToPlaySolo = false;
      }
    });
    if (pak.downloadProgress >= 1){
        this.$spacewalk.ue.PlaySolo(this.world._id, this.world.pakId, this.world.title);
        return;
    }
    let started = false;
    packages.vm.$watch(function(){return pak.downloadProgress}, progress => {
      if (started){
        return;
      }
      if (progress >= 1){
        this.$spacewalk.ue.PlaySolo(this.world._id, this.world.pakId, this.world.title);
        started = true;
      }
    })
    this.$spacewalk.ue.DownloadPak(this.world.pakId);
  }
  waitingToMove = false;
  moveEvent(){
    this.waitingToMove = true;
    events.move(this.world).then(()=>{
      this.waitingToMove = false;
    });
  }
  get downloadProgress(){
    if (!this.world || !this.world.pakId) return 0;
    return packages.get(this.world.pakId).downloadProgress;
  }
  get isCreator(){
    return users.current?._id === this.world.createdBy || this.isAdmin;
  }
  get isAdmin(){
    return this.$spacewalk.native.auth.claims.viewalk === 'admin';
    // return !!users.current?.permissions.official || !!this.$spacewalk.native.auth.claims.official;
  }
  setSinglePlayer(value: boolean){
    return worlds.update(this.world._id, {
      singlePlayer: value,
    });
  }
  setMultiPlayer(value: boolean){
    return worlds.update(this.world._id, {
      multiPlayer: value,
    });
  }
  descriptionSaving = false;
  setDescription(value: string){
    this.descriptionSaving = true;
    return worlds.update(this.world._id, {
      description: value,
    }).finally(()=>{
      this.descriptionSaving = false;
    });
  }
  titleSaving = false;
  setTitle(value: string){
    this.titleSaving = true;
    return worlds.update(this.world._id, {
      title: value,
    }).finally(()=>{
      this.titleSaving = false;
    });
  }
  setPublic(value: boolean){
    return worlds.update(this.world._id, {
      public: value,
    });
  }
  setOfficial(value: boolean){
    return worlds.update(this.world._id, {
      official: value,
    }).catch(error => {
      this.world.official = !value;
    });
  }
  setFeatured(featured: boolean){
    const featuredAt = featured ? new Date() : null;
    return worlds.update(this.world._id, {
      featuredAt,
    });
  }
}