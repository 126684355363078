import { Component, Prop, Vue } from 'vue-property-decorator';

import users from '../state/users';

import * as human from '@/functions/human';

import Mana from '@/components/Mana.vue';

@Component({
  components: {
    Mana,
  }
})
export default class DailyGoal extends Vue {
  goal = 60*15;
  get movingTime(){
    const dailyMoveSeconds = users.current?.counters?.dailyMoveSeconds?.value || 0;
    return dailyMoveSeconds + this.$spacewalk.reactive.bufferedMoveTime;
  }
  get subtitle(){
    return `Moving time: ${human.duration(this.movingTime || 0, true)}<br />Daily goal: ${human.duration(this.goal)}`
  }
  get progress(){
    return this.movingTime / this.goal;
  }
  onClick(){
    this.$router.push('/account');
  }
}