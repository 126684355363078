import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import spacewalk from './plugins/spacewalk'
import analytics from './plugins/analytics'
import '@mdi/font/css/materialdesignicons.css'
import '@/styles/global.scss'

// // https://cli.vuejs.org/guide/browser-compatibility.html#usebuiltins-usage
// import 'core-js/stable';
// import 'regenerator-runtime/runtime';

Vue.config.productionTip = false
Vue.use(spacewalk);
Vue.use(analytics);

Vue.filter('round', function (value: number) {
  return Math.round(value);
})

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
