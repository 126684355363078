import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import users from '@/state/users'
import type { Rule } from '@/functions/rules';

@Component
export default class TextFieldBuffered extends Vue {
  @Prop({default: ''}) value!: string;
  @Prop({default: ''}) label!: string;
  @Prop({default: 'text'}) type!: string;
  @Prop({default: false}) autofocus!: boolean;
  @Prop({default: false}) loading!: boolean;
  @Prop({default() {return []}}) rules!: Rule[];
  @Prop({default: ''}) hint!: string;
  @Prop({default: false}) area!: boolean;
  valid = false;
  focus = false;
  bufferedValue = '';
  mounted(){
    this.onValueChange(this.value, '');
    // this.bufferedValue = this.value;
    // console.log(this.bufferedValue, this.value);
  }
  @Watch('value')
  onValueChange(to: string, from: string){
    if (this.bufferedValue === from){
      this.bufferedValue = to;
    }
  }
  submit(){
    if (!this.valid){
      return;
    }
    this.$emit('input', this.bufferedValue);
    (this.$refs.textField as HTMLElement).blur();
  }
}