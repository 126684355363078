import { Component, Prop, Vue } from 'vue-property-decorator';
import { World } from '../state/worlds';
import packages from '../state/packages';

@Component
export default class WorldCard extends Vue {
  @Prop({required: true}) world!: World;
  get downloadRequired(){
    if (!this.$spacewalk.ue){
      return false;
    }
    if (!this.world.pakId){
      return false;
    }
    return packages.get(this.world.pakId).downloadProgress < 1;
  }
}