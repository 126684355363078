import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import users from '@/state/users'

import TextFieldBuffered from '@/components/TextFieldBuffered.vue';
import XpDetails from '@/components/XpDetails.vue'
import rules from '@/functions/rules';

@Component({
  components: {
    TextFieldBuffered,
    XpDetails,
  }
})
export default class Profile extends Vue {
  share(){
    if (!this.profile){
      return;
    }
    return navigator.share({
      title: this.profile.displayName,
      text: `${this.profile.displayName} on Viewalk!`,
      url: `https://api.spacewalk.no/users/og/${this.auth._id}`,
    });
  }
  colorDialog = false;
  @Watch('$spacewalk.reactive.auth.email')
  onEmailChange(to: string){
    console.log('onEmailChange', to);
    if (!!to){
      this.emailAddressUnverified = '';
      this.emailVerificationReference = '';
    }
  }
  setUnconfirmedEmail(emailAddress: string, emailReference: string){
    if (localStorage){
      localStorage.setItem('spacewalk.emailAddress', emailAddress);
      localStorage.setItem('spacewalk.emailReference', emailReference);
    }
    this.emailAddressUnverified = emailAddress;
    this.emailVerificationReference = emailReference;
  }
  get auth(){
    return this.$spacewalk.reactive.auth;
  }
  mounted(){
    console.log('profile.mounted');
    if (localStorage){
      const emailAddress = localStorage.getItem('spacewalk.emailAddress');
      const emailReference = localStorage.getItem('spacewalk.emailReference');
      if (emailAddress === this.emailAddress && this.auth.emailVerified){
        localStorage.removeItem('spacewalk.emailAddress');
        localStorage.removeItem('spacewalk.emailReference');
      }
    }
  }
  get profile(){
    if (!this.auth._id){
      return null;
    }
    return users.get(this.auth._id);
  }
  saveDisplayName(value: string){
    if (!this.auth._id){
      return;
    }
    this.$analytics.event('profile.update', {
      displayName: true,
    });
    this.displayName.loading = true;
    users.update(this.auth._id, {
      displayName: value,
    }).then(user=>{
      this.displayName.loading = false;
      this.$spacewalk.ue.UpdateCachedUser(JSON.stringify(user));
    });
  }
  saveColor(value: string){
    if (!this.auth._id){
      return;
    }
    this.$analytics.event('profile.update', {
      color: true,
    });
    users.update(this.auth._id, {
      color: value,
    }).then(user=>{
      this.$spacewalk.ue.UpdateCachedUser(JSON.stringify(user));
    })
  }
  displayName = {
    loading: false,
    rules: [
      rules.notEmpty,
    ],
  }
  email = {
    loading: false,
    rules: [],
    // reference: '',
    // address: '',
  }
  emailAddressUnverified = '';
  emailVerificationReference = '';
  get emailAddress(){
    let emailAddress = this.auth.email;
    if (this.emailAddressUnverified && this.emailVerificationReference){
      emailAddress = this.emailAddressUnverified;
    }
    return emailAddress;
  };
  get emailVerified(){
    return this.auth.emailVerified
  };
  saveEmailAddress(value: string){
    this.setUnconfirmedEmail('', '');
    return this.$spacewalk.native.auth.setEmailAddress(value).then(res => {
      this.email.loading = false;
      this.setUnconfirmedEmail(value, res.reference);
    });
  }
  setInvertX(value: boolean){
    this.$analytics.event('profile.update', {
      invertX: value,
    });
    return users.update(this.auth._id, {
      invertX: value,
    }).then(user=>{
      this.$spacewalk.ue.UpdateCachedUser(JSON.stringify(user, null, 4));
    });
  }
  setInvertY(value: boolean){
    this.$analytics.event('profile.update', {
      invertY: value,
    });
    return users.update(this.auth._id, {
      invertY: value,
    }).then(user=>{
      this.$spacewalk.ue.UpdateCachedUser(JSON.stringify(user, null, 4));
    });
  }
}