import Vue from 'vue';
// import { DateTime } from 'luxon';
import type { Achievements } from 'viewalk-types';

import { client } from '@/plugins/spacewalk';

const minRequestInterval = 60*1000;

export interface Achievement extends Required<Achievements.Achievement> {
  // date: DateTime;
  requestedAt: number;
  loaded: boolean;
}
type AchievementWithId = Required<Pick<Achievement, '_id'>> & Partial<Achievement>;

interface State {
  all: Achievement[];
}
const vm = new Vue<State>({
  data: {
    all: [],
  }
})

const assert = function(_id: string): Achievement{
  if (!_id){
    console.error(_id);
    throw new Error('Invalid id');
  }
  let entry = vm.all.find(exp => exp._id === _id);
  if (!entry){
    entry =  {
      _id,
      title: '',
      xp: 0,
      repeatable: null,
      requestedAt: 0,
      img: '',
      loaded: false,
      description: '',
      createdAt: new Date(0),
      createdBy: '',
      updatedAt: new Date(0),
      deletedAt: null,
      listed: false,
      secret: false,
      events: [],
      counter: '',
      countsRequired: 0,
      achieved: 0,
      achievers: 0,
    }
    vm.all.push(entry);
  }
  return entry;
}

export default {
  get vm(){
    return vm;
  },
  insertOne(input: AchievementWithId){
    const entry = assert(input._id);
    for (const key of Object.keys(input)){
      if (key === 'img' && typeof input.img === 'string'){
        entry.img = input.img;
        if (!entry.img.startsWith('https://')){
          entry.img = `https://assets.spacewalk.no/achievements/${entry._id}/${entry.img}`;
        }
      } else {
        (entry as any)[key] = (input as any)[key];
      }
    }
    return entry;
  },
  get(_id: string){
    if (!_id){
      throw new Error('No id provided');
    }
    const entry = assert(_id);
    const now = Date.now();
    if (now - entry.requestedAt > minRequestInterval){
      entry.requestedAt = now;
      client.native.achievements.read(_id).then(res => {
        console.log(res);
        for (const achievement of res.achievements){
          this.insertOne(achievement).loaded = true;
        }
      });
    }
    return entry;
  },
}