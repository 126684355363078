import { Component, Prop, Vue } from 'vue-property-decorator';
import events, {SpacewalkEvent} from '../state/events';
import users from '../state/users';
import worlds from '../state/worlds';

type RSVP = '' | 'Interested' | 'Going' | 'Not going';
const rsvpValues: RSVP[] = [
  '',
  'Going',
  'Interested',
  'Not going',
];
function randomRsvp(){
  const i = Math.floor(Math.pow(Math.random(), 3)*rsvpValues.length);
  const rsvp = rsvpValues[i];
  console.log(i, rsvp)
  return rsvp;
}
@Component
export default class EventCard extends Vue {
  @Prop() event!: SpacewalkEvent;
  rsvp = '';
  get rsvpClasses() {
    let classes = '';
    switch (this.rsvp){
      case 'Going':
        classes += ' success black--text';
        break;
      case 'Interested':
        classes += ' warning';
        break;
      case 'Not going':
        classes += ' error';
        break;
      default:
        break;
    }
    return classes;
  }
  get rsvpColor() {
    switch (this.rsvp){
      case 'Going':
        return 'success';
      case 'Interested':
        return 'warning';
      case 'Not going':
        return 'error';
      default:
        return '';
    }
  }
  get world(){
    if (!this.event.mapId){
      return null;
    }
    return worlds.get(this.event.mapId);
  }
  get time(){
    if (!this.event.startsAt){
      return '';
    }
    return this.event.startsAt.substr(11, 5);
  }
  get host(){
    if (!this.event.createdBy){
      return null;
    }
    return users.get(this.event.createdBy);
  }
  get subtitle(){
    if (this.host){
      return `Hosted by ${this.host.displayName}`;
    } else {
      return 'Public event';
    }
  }
}