import Vue from 'vue';
import type { Packages } from 'viewalk-types';
import { client } from '@/plugins/spacewalk';

export interface Package extends Required<Packages.Package> {
  loaded: boolean;
  downloadProgress: number;
}
type PackageWithId = Required<Pick<Package, '_id'>> & Partial<Package>;

const assert = function(_id: string): Package{
  if (!_id){
    console.error(_id);
    throw new Error('Invalid id');
  }
  let entry = vm.all.find(exp => exp._id === _id);
  if (!entry){
    entry =  {
      loaded: false,
      _id,
      createdBy: '',
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: new Date(),
      downloadProgress: Number(client.ue && client.ue.HasPak(_id)),
      assetName: '',
      type: 'map',
      error: '',
      log: '',
      size: 0,
      status: '',
      cookTime: 0,
      versions: {},
      apiLevel: 0,
      comment: '',
    }
    if (client.ue){
      client.ue.HasPak(_id).then(hasPak => {
        if (hasPak){
          entry!.downloadProgress = 1;
        }
      });
    }
    vm.all.push(entry);
    console.log(`Asserted package: ${_id}`);
  }
  return entry;
}
interface State {
	all: Package[];
  current: string;
  pending: string;
}
const vm = new Vue<State>({
  data: {
    all: [],
    current: '',
    pending: '',
  }
})

export default {
  get vm(){
    return vm;
  },
  insertOne(input: PackageWithId){
    const entry = assert(input._id);
    for (const key of Object.keys(input)){
      (entry as any)[key] = (input as any)[key];
    }
    entry.loaded = true;
    return entry;
  },
  get(_id: string){
    if (!_id){
      throw new Error('No id provided');
    }
    return assert(_id);
  },
  get current(){
    return vm.current;
  },
  set current(_id: string){
    vm.current = _id;
  },
  setProgress(_id: string, progress: number){
    assert(_id).downloadProgress = progress;
  },
  setComplete(_id: string, success: boolean){
    assert(_id).downloadProgress = Number(success);
  },
  update(_id: string, updates: Partial<Packages.Package>){
    return client.native.assets.pakUpdate(_id, updates).then(res => {
      this.insertOne(res);
      console.log(res);
    });
  }
}