import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: '#006cff',
        // accent: '#009afd',
        accent: '#ffffff',
        secondary: '#e45cfe',
        info: '#07e9ef',
        warning: '#ffa508',
        error: '#fd5000',
        success: '#11e785',
        // Viewalk colors
        lime: '#cdf002',
        teal: '#01E8EF',
        purple: '#8148F6',
      },
      dark: {
        primary: '#43ffb1',
        accent: '#BFBFBF',
        secondary: '#000',
        info: '#3b4e5c',
        warning: '#f8cd00',
        error: '#ff49a1',
        success: '#43ffb1',
      },
    },
  },
});
