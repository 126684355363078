import { Component, Vue } from 'vue-property-decorator';
import World from '@/components/World.vue'; // @ is an alias to /src

import worlds from '../state/worlds';

@Component({
  components: {
    World,
  },
})
export default class WorldView extends Vue {
  get world(){
    return worlds.get(this.$route.params.id);
  }
}