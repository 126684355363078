import {DateTime, Duration} from 'luxon';

export function date(input: string){
  const now = DateTime.now();
  const date = DateTime.fromISO(input);
  const daysAway = date.startOf('day').diff(now.startOf('day'), 'days');
  if (daysAway.days < 2){
    return  date.toRelativeCalendar({
      unit: 'days',
    }) || '';
  }
  if (daysAway.days < 4){
    return date.weekdayLong;
  }
  return date.toLocaleString({
    weekday: 'short',
    day: 'numeric',
    month: 'short',
  })
}

export function time(dateTime: string){
  return dateTime.substring(11, 16);
}
export function duration(seconds: number, compact = false){
  if (seconds < 60){
    return Duration.fromMillis(Math.round(seconds)*1000).shiftTo("seconds").toHuman();
  }
  
  if (seconds < 60*60){
    if (compact){
      return `${Math.floor(seconds/60)}:${String(Math.floor(seconds) % 60).padStart(2, '0')}`
    }
    let duration = Duration.fromObject({seconds: Math.round(seconds)}).shiftTo("seconds", "minutes");
    if (!duration.seconds){
      duration = duration.shiftTo("minutes");
    }
    return duration.toHuman();
  }
  if (seconds < 60*60*24){
    return Duration.fromObject({minutes: Math.floor(seconds/60)}).shiftTo("minutes", "hours").toHuman();
  }
  else {
    return Duration.fromObject({minutes: Math.floor(seconds/60)}).shiftTo("minutes", "hours", "days").toHuman();
  }
}

export function distance(meters: number){
  // return new Intl.NumberFormat("en-US", {
  //   style: "unit",
  //   unit: "kilometer",
  // }).format(meters * 0.001);
  if (meters >= 1000){
    return `${Math.floor(meters * 0.001)} km`;
  } else {
    return `${Math.floor(meters)} m`;
  }
}