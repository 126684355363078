import { Component, Prop, Vue } from 'vue-property-decorator';
import worlds, { World } from '../state/worlds';
import packages from '../state/packages';
import events, {SpacewalkEvent} from '../state/events';
import users from '../state/users';
import { DateTime } from 'luxon';
import { Sessions } from 'viewalk-types';
import DownloadProgress from '@/components/DownloadProgress.vue';

@Component({
  components: {
    DownloadProgress
  }
})
export default class WorldEvents extends Vue {
  @Prop({required: true}) value!: World | string;
  get world(){
    if (typeof this.value === 'string'){
      return worlds.get(this.value);
    }
    return this.value;
  }
  get events(){
    const now = DateTime.now().toISO();
    return events.getByWorld(this.world._id).filter(e => !!e.createdBy).filter(ev => {
      if (ev.startsAt && ev.startsAt.substr(0, 10) > now){
        return false;
      }
      if (!ev.ready){
        return false;
      }
      return true;
    }).sort((a, b) => {
      if (a.playersCur === b.playersCur){
        return  a.createdAt.valueOf() - b.createdAt.valueOf();
      }
      return b.playersCur - a.playersCur;
    }).filter(event => !event.deletedAt).slice(0, 5);
  }
  selected = '';
  status = '';
  error = '';
  waitingToPlaySolo = false;
  playDialog = false;
  get currentWorld(){
    if (!worlds.current){
      return null;
    }
    return worlds.get(worlds.current);
  }
  get currentEvent(){
    return events.current;
  }
  get isCurrentEventAdmin(){
    return events.isCurrentAdmin;
  }
  goSolo(domEvent: Event){
    domEvent.stopPropagation();
    domEvent.preventDefault();
    this.waitingToPlaySolo = true;
    this.status = 'Downloading ...';
    packages.vm.$watch('current', pakId => {
      if (pakId === this.world.pakId){
        this.waitingToPlaySolo = false;
      }
    });
    this.$spacewalk.ue.PlaySolo(this.world._id, this.world.pakId, this.world.title);
  }
  joinPublic(domEvent: Event){
    domEvent.stopPropagation();
    domEvent.preventDefault();
    this.selected = 'public';
    this.status = 'Downloading ...';
    console.error('Not yet implemented');
  }
  joinEvent(domEvent: Event, id: string){
    console.log('Not yet implemented');
    domEvent.stopPropagation();
    domEvent.preventDefault();
    this.status = 'Downloading ...';
    this.selected = id;
    const event = events.get(id);
    return this.$spacewalk.native.sessions.join(event._id).then((res: Sessions.JoinResponse) => {
      // console.log('res.url', res.url);  
      return this.$spacewalk.ue.Travel(event._id, res.url, event.pakIds);
      // this.$router.replace({
      //   name: 'Playing',
      // });
    });
    console.error('Not yet implemented');
  }
  host(event: SpacewalkEvent){
    if (!event.createdBy){
      return null;
    }
    return users.get(event.createdBy);
  }
  cancel(){
    this.selected = '';
    this.status = '';
    // this.world.downloadProgress = 0;
  }
  get downloadProgress(){
    if (!this.world.pakId){
      return 0;
    }
    return packages.get(this.world.pakId).downloadProgress;
  }
  get isDownloading(){
    return this.downloadProgress > 0 && this.downloadProgress < 1;
  }
  moveTo(){
    events.move(this.world).then(()=>{
      this.playDialog = false;
    });
  }
}