import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import events, {SpacewalkEvent} from '../state/events';
import users from '../state/users';
import worlds, { World } from '../state/worlds';
import packages from '../state/packages';

import type { Sessions } from 'viewalk-types';
import WorldPicker from '@/components/WorldPicker.vue';
import EventCode from '@/components/EventCode.vue';

// import { VTextField } from 'vuetify/lib';

@Component({
  components: {
    EventCode,
    WorldPicker,
  }
})
export default class EventComponent extends Vue {
  @Prop({required: true}) event!: SpacewalkEvent;
  get events(){
    return events;
  }
  @Watch('events.current')
  onCurrentEventChange(){
    if (events.current === this.event._id){
        this.$router.replace({
          name: 'Playing',
        });
    }
  }
  mounted(){
    worlds.refresh();
    this.$spacewalk.refreshCurrentSessionId();
  }
  share(){
    return navigator.share({
      title: this.event.title,
      text: `Join ${this.event.title} on Viewalk!`,
      url: `https://api.spacewalk.no/events/og/${this.event._id}`,
    });
  }
  host(){
    return this.$spacewalk.ue.Host(this.event._id, worlds.get(this.event.mapId).pakId);
  }
  joining = false;
  joinError = '';
  connectionError = '';
  attemptToJoin = false;
  joined = false;
  get connected(){
    return events.current === this.event._id;
  }
  join(){
    this.attemptToJoin = true;
    this.joining = true;
    this.connectionError = '';
    return this.$spacewalk.native.sessions.join(this.event._id).then((res: Sessions.JoinResponse) => {
      this.joined = true;
      // console.log('res.url', res.url);  
      return this.$spacewalk.ue.Travel(this.event._id, res.url, this.event.pakIds).then(()=>{
        const callback = (event: Event) => {
          this.joining = false;
          // this.joinErrorMessages = (event as CustomEvent).detail.errorString;
          this.connectionError = 'Could not connect to server.'
        };
        this.$spacewalk.webview.addEventListener('NetworkFailure', callback, {once: true});
        events.vm.$watch('current', sessionId => {
          this.joining = false;
          this.$spacewalk.webview.removeEventListener('NetworkFailure', callback)
        })
      })
      // this.$router.replace({
      //   name: 'Playing',
      // });
    }).catch(()=>{
      this.joining = false;
    })
  }
  leave(){
    return this.$spacewalk.ue.Leave(this.event._id);
  }
  deleting = false;
  deleteEvent(){
    this.deleting = true;
    return events.delete(this.event._id).then(()=>{
      this.deleting = false;
      this.$router.push({name: 'Events'});
      // setTimeout(()=>{
      //   this.$router.push({name: 'Events'});
      // }, 1000);
    });
  }
  get canJoin(){
    if (!this.$spacewalk.ue){
      return false;
    }
    if (events.current === this.event._id){
      return false;
    }
    if (this.event.locallyHosted && !this.event.ready){
      return false;
    }
    return true;
  }
  get canHost(){
    if (!this.event.locallyHosted){
      return false;
    }
    if (!this.$spacewalk.ue){
      return false;
    }
    if (events.current === this.event._id){
      return false;
    }
    if (this.event.createdBy !== this.$spacewalk.reactive.auth._id){
      return false;
    }
    return true;
  }
  get canLeave(){
    if (!this.$spacewalk.ue){
      return false;
    }
    if (events.current !== this.event._id){
      return false;
    }
    return true;
  }
  get canUpdate(){
    if (events.current !== this.event._id){
      return false;
    }
    return this.event.admins.includes(this.$spacewalk.reactive.auth._id);
  }
  get canDelete(){
    return this.event.createdBy === this.$spacewalk.reactive.auth._id;
  }
  get settingWorld(){
    return false;
    // return this.event.mapId !== this.worldId;
  }
  get downloadProgress(){
    if (!this.event.mapId){
      return 0;
    }
    const world = worlds.get(this.event.mapId);
    if (!world.pakId){
      return 0;
    }
    return packages.get(world.pakId).downloadProgress;
  }
  get isDownloading(){
    return this.downloadProgress > 0 && this.downloadProgress < 1;
  }
  // setWorld(mapId: string){
  //   return events.setWorld(this.event._id, mapId);
  // }
} 