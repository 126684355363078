import { Component, Prop, Vue } from 'vue-property-decorator';
import {DateTime} from 'luxon';

import * as human from '../functions/human'

@Component
export default class DateTimePicker extends Vue {
  @Prop() readonly?: boolean;
  @Prop({required: true}) value!: string;
  model = '';
  get date(){
    return DateTime.fromISO(this.value).toISODate();
    // return this.value.substr(0, 8);
  }
  set date(isoDate: string){
    this.$emit('input', isoDate+this.value.substr(10));
  }
  get time(){
    return DateTime.fromISO(this.value).toISOTime();
  }
  set time(isoTime: string){
    this.$emit('input', this.value.substr(11)+isoTime);
  }
  showDatePicker = false;
  showTimePicker = false;

  now(){
    return DateTime.now().toISO();
  }
  get humanDate(){
    const string = human.date(this.value);
    if (string){
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }
  get humanTime(){
    return human.time(this.value);
  }
}