import { Component, Prop, Vue } from 'vue-property-decorator';
import {client as spacewalk} from './spacewalk'
import router from '@/router'

declare module 'vue/types/vue' {
  interface Vue {
    $analytics: Analytics;
  }
}

@Component
export class Analytics extends Vue {
  mounted(){
    spacewalk.native.log
  }
  async event(event: string, properties?: {[key: string]: any}){
    if (!spacewalk.reactive.auth._id){
      return;
    }
    let platform = 'webview';
    let localSessionStartedAt: number | undefined = undefined;
    if (spacewalk.ue){
      localSessionStartedAt = await spacewalk.ue.GetAnalyticsSessionStartedAt().catch(()=>{
        return undefined;
      });
      platform = await spacewalk.ue.GetPlatform().catch(()=>{
        return platform;
      });
    }
    spacewalk.native.log.create([{
      event,
      data: {
        ... properties,
      },
      platform,
      localSessionStartedAt,
    }]);
  }
  pageView(path?: string){
    if (!path){
      path = this.$route.path;
    }
    return this.event('page.view', {path});
  }
}
export default function install () {
  const analytics = new Analytics();
  Vue.prototype.$analytics = analytics;
  router.afterEach(( to, from ) => {
    analytics.pageView(to.path);
  });
}
