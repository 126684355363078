import Vue from 'vue';
import { DateTime } from 'luxon';

import type { Sessions } from 'viewalk-types';
import worlds, { World } from './worlds';
import packages from './packages';
import { client } from '@/plugins/spacewalk';

const minRequestInterval = 60*1000;

export interface SpacewalkEvent extends Required<Sessions.Session> {
  loaded: boolean;
  startsAt: string;
  endsAt: string;
  img?: string;
  requestedAt: number;
  invites: string[];
}
type EventWithId = Required<Pick<SpacewalkEvent, '_id'>> & Partial<SpacewalkEvent>;

interface State {
	all: SpacewalkEvent[];
	current: string;
}
const vm = new Vue<State>({
  data: {
    all: [],
    current: '',
  }
});
const assert = function(_id: string): SpacewalkEvent{
  if (!_id){
    console.error(_id);
    throw new Error('Invalid id');
  }
  let entry = vm.all.find(exp => exp._id === _id);
	const now = new Date();
  if (!entry){
    entry =  {
			loaded: false,
			createdAt: now,
			createdBy: '',
			updatedAt: now,
			deletedAt: null,
			buildId: 0,
      title: '',
			_id,
			mapId: 'Lobby',
			playersCur: 0,
			address: '',
			port: 0,
			ready: false,
      attendees: [],
			admins: [],
			players: [],
			playersMax: 0,
			network: '',
			gameMode: '',
			pakIds: [],
			startsAt: '',
			endsAt: '',
			locallyHosted: false,
      public: false,
      requestedAt: 0,
      invites: [],
      serverImage: '',
    }
    vm.all.push(entry);
    console.log(`Asserted event: ${_id}`);
  }
  return entry;
}

export default {
  get vm(){
    return vm;
  },
  insertOne(input: EventWithId){
    const entry = assert(input._id);
    for (const key of Object.keys(input)){
      if (key === 'img' && typeof input.img === 'string'){
        entry.img = input.img;
        if (!entry.img.startsWith('https://')){
          entry.img = `https://assets.spacewalk.no/worlds/${entry._id}/${entry.img}`;
        }
      } else {
        (entry as any)[key] = (input as any)[key];
      }
    }
    return entry;
  },
  insert(inputs: EventWithId[]){
    const entries: SpacewalkEvent[] = [];
    for (const input of inputs){
      entries.push(this.insertOne(input));
    }
    return entries;
  },
  get(_id: string){
    if (!_id){
      throw new Error('No id provided');
    }
    const entry = assert(_id);
    const now = Date.now();
    if (now - entry.requestedAt > minRequestInterval){
      entry.requestedAt = now;
      client.native.sessions.read(_id).then(input => {
        this.insertOne(input.session);
        entry.loaded = true;
        entry.invites = input.invites;
      });
    }
    return entry;
  },
	// getByExperience(id: string){
	// 	return all.filter(entry => entry.experienceId === id);
	// },
	getByWorld(id: string, current=true){
		const events = vm.all.filter(entry => entry.mapId === id);
    if (!current){
      return events;
    }
    const now = DateTime.now().toISO();
    return events.filter(e => !!e.createdBy).filter(ev => {
      if (ev.startsAt && ev.startsAt.substr(0, 10) > now){
        return false;
      }
      if (!ev.ready){
        return false;
      }
      return true;
    }).sort((a, b) => {
      if (a.playersCur === b.playersCur){
        return b.createdAt.valueOf() - a.createdAt.valueOf();
      }
      return b.playersCur - a.playersCur;
    });
	},
	get all(){
		return vm.all;
	},
	get current(){
		return vm.current;
	},
	set current(_id: string){
		vm.current = _id;
	},
  get isCurrentAdmin(){
    if (!this.current){
      return false;
    }
    return this.get(this.current).admins.includes(client.reactive.auth._id);
  },
  refresh(){
    return client.native.sessions.find(client.reactive.buildId).then(sessions => {
      this.insert(sessions).forEach(entries => {
        entries.loaded = true;
      });
    });
  },
  create(event: Partial<SpacewalkEvent>){
  	console.log(event);
		return client.native.sessions.create(event).then(session => {
			console.log('session', session);
			return this.insertOne(session);
		});
  },
  delete(_id: string){
		return client.native.sessions.delete(_id).then(res => {
			return this.insertOne(res.session);
		});
  },
  getTravelUrl(event: SpacewalkEvent){
  	if (!event.address){
  		throw new Error('event.address is missing');
  	}
  	if (!event.port){
  		throw new Error('event.port is missing');
  	}
  	return `${event.address}:${event.port}`;
  },
  // setWorld(eventId: string, worldId: string){
  // 	const event = assert(eventId);
  //   const pakId = worlds.get(worldId).pakId;
  //   client.native.sessions.update(event._id, {
  //     mapId: worldId,
  //     pakIds: [pakId],
  //   }).then(()=>{
  //   	if (client.ue && this.current === eventId){
	 //      client.ue.SetMap(event._id, pakId).then(()=>{
	 //        //
	 //      });
  //   	}
  //   });
  // },
  get canCloudHost(){
    return true;
  },
  get canHostLocally(){
    return true;
  },
  get canHostPublic(){
    return true;
  },
  move(world: World){
    return new Promise(resolve => {
      const pak = packages.get(world.pakId);
      if (pak.downloadProgress >= 1){
        client.ue.MoveEvent(world._id, world.pakId, world.title);
        resolve(true);
        return;
      }
      let started = false;
      packages.vm.$watch(function(){return pak.downloadProgress}, progress => {
        if (started){
          return;
        }
        if (progress >= 1){
          client.ue.MoveEvent(world._id, world.pakId, world.title);
          resolve(true);
          started = true;
        }
      })
      client.ue.DownloadPak(world.pakId);
    })
  }
}