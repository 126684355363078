import { Component, Vue } from 'vue-property-decorator';

import World from '@/components/World.vue'; // @ is an alias to /src
import XpDetails from '@/components/XpDetails.vue';

import users from '../state/users';

@Component({
  components: {
    World,
    XpDetails,
  },
})
export default class UserView extends Vue {
  get id(){
    return this.$route.params.id;
  }
  get user(){
    return users.get(this.id);
  }
  get canAdd(){
    if (!users.current){
      return false;
    }
    return !users.current.friends.includes(this.id);
  }
  get canCancel(){
    if (!users.current){
      return false;
    }
    if (!users.current.friends.includes(this.id)){
      return false;
    }
    return !this.user.friends.includes(users.current._id);
  }
  get canRemove(){
    if (!users.current){
      return false;
    }
    if (!users.current.friends.includes(this.id)){
      return false;
    }
    return this.user.friends.includes(users.current._id);
  }
  add(){
    if (!users.current){
      return;
    }
    return users.update(users.current._id, {
      friendAdd: this.id,
    });
  }
  cancel(){
    if (!users.current){
      return;
    }
    return users.update(users.current._id, {
      friendCancel: this.id,
    });
  }
  remove(){
    if (!users.current){
      return;
    }
    return users.update(users.current._id, {
      friendRemove: this.id,
    });
  }
}