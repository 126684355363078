import Vue from 'vue';
import { DateTime } from 'luxon';
import type { Xp } from 'viewalk-types';

import { client } from '@/plugins/spacewalk';

export interface XpEntry extends Required<Xp.Entry> {
  date: DateTime;
}

interface State {
  all: XpEntry[];
  requests: {
    [key: string]: number;
  }
}
const vm = new Vue<State>({
  data: {
    all: [],
    requests: {}
  }
})

export default {
  get vm(){
    return vm;
  },
  byUser(userId: string){
    if (!userId){
      userId = client.reactive.auth._id;
    }
    const now = Date.now();
    if ((vm.requests[userId] || 0) < now - 2000){
      vm.requests[userId] = now;
      client.native.xp.read(userId).then(res => {
        vm.all = res.entries as XpEntry[];
        for (const entry of vm.all){
          entry.date = DateTime.fromSeconds(parseInt(entry._id.substring(0, 8), 16));
        }
      });
    }
    return vm.all.filter(entry => entry.user === userId);
  }
}