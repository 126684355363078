



import { Component, Vue } from 'vue-property-decorator';
import EventsCreate from '@/components/EventsCreate.vue';
@Component({
  components: {
    EventsCreate,
  },
})
export default class EventsCreateView extends Vue {}
