import { Component, Prop, Vue } from 'vue-property-decorator';
// import achievementsState from '../state/achievements';
import users from '@/state/users'
// import AchievementCard from '@/components/AchievementCard.vue';

const levels = [
  0,
  1000,
  3000,
  6000,
  10000,
  15000,
  21000,
  28000,
  36000,
  45000,
];
@Component
export default class Xp extends Vue {
  @Prop() userId?: string;
  get user(){
    const userId = this.userId ? this.userId : users.current?._id;
    if (!userId){
      return null;
    }
    return users.get(userId);
  }
  get xpTotal(){
    return this.user?.xp || 0;
  }
  get xpCurrent(){
    return this.xpTotal - levels[this.levelCurrent-1];
  }
  get xpNext(){
    return levels.filter(xp => xp > this.xpTotal)[0] - levels[this.levelCurrent-1];
  }
  get levelCurrent(){
    let level = 0;
    while (levels[level] <= this.xpTotal){
      level ++;
    }
    return level;
  }
  get levelProgress(){
    return this.xpCurrent/this.xpNext * 100;
  }
}