import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class Logo extends Vue {
	@Prop({default: 'black'}) color!: string;
	@Prop() title!: string;
	@Prop() subtitle!: string;
	@Prop({default: .5}) value!: number;
	// @Prop({default: 4}) variant!: number;
	get percent(){
		return `${Math.round(this.value*100)}%`
	}
	get transform(){
		let y = (0.5 - Math.pow(this.value, .8)) * 550;
		return `translate(0, ${y})`;
	}
}