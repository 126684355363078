import Vue from 'vue';
import type { Users } from 'viewalk-types';
import { client } from '@/plugins/spacewalk';

const minRequestInterval = 60*1000;
export interface User extends Required<Users.Profile> {
  loaded: boolean;
  requestedAt: number;
}
type UserWithId = Required<Pick<User, '_id'>> & Partial<User>;

const assert = function(_id: string): User{
  if (!_id){
    console.error(_id);
    throw new Error('Invalid id');
  }
  let entry = vm.all.find(exp => exp._id === _id);
  if (!entry){
    const now = new Date();
    entry =  {
      loaded: false,
      _id,
      displayName: '',
      color: '#888888',
      hMovement: 0,
      devices: {},
      notifications: [],
      img: '',
      friends: [],
      requestedAt: 0,
      permissions: {
        official: false,
      },
      achievements: {},
      xp: 0,
      counters: {},
      updatedAt: new Date(0),
      walked: 0,
      platforms: [],
      playedSince: now,
      firstName: '',
      lastName: '',
      email: '',
      born: now.getFullYear(),
      gender: '',
      tos: false,
      pp: false,
      earlyAccessRequested: false,
      newsletter: false,
      invertX: false,
      invertY: false,
      moveTime: 0,
    }
    vm.all.push(entry);
  }
  return entry;
}
interface State {
  all: User[];
}
const vm = new Vue<State>({
  data: {
    all: [],
  }
});
const mod = {
  insert(inputs: Partial<Users.Profile>[]){
    for (const input of inputs){
      const inputAsAny = input as any;
      if (!input._id){
        continue;
      }
      const entry = assert(input._id);
      for (const key of Object.keys(input)){
        if (['updatedAt', 'playedSince'].includes(key) && typeof inputAsAny[key] === 'string'){
          (entry as any)[key] = new Date(inputAsAny[key]);
        } else {
          (entry as any)[key] = inputAsAny[key];
        }
      }
      entry.loaded = true;
    }
  },
  get(_id: string){
    if (!_id){
      throw new Error('No id provided');
    }
    const entry = assert(_id);
    const now = Date.now();
    if (now - entry.requestedAt > minRequestInterval){
      entry.requestedAt = now;
      client.native.users.read(_id).then(user => {
        this.insert([user]);
        // for (const key of Object.keys(user)){
        //   (entry as any)[key] = (user as any)[key];
        // }
      }).catch(() => {
        entry.loaded = true;
      });
    }
    return entry;
  },
  get all(){
    return vm.all;
  },
  get current(){
    if (!vm.$spacewalk.reactive.auth._id){
      return null;
    }
    return this.get(vm.$spacewalk.reactive.auth._id);
  },
  update(_id: string, updates: Users.ProfileUpdate){
    return client.native.users.update(_id, updates).then(user => {
      this.insert([user]);
      return this.get(_id);
    });
  },
  initials(user: User){
    return user.displayName.split(' ').map(word => word[0]).join('');
  },
  get friends(){
    if (!this.current?.friends){
      return [];
    }
    return this.current.friends.map(id => this.get(id));
  }
}
client.native.achievements.on('achievement', achievement =>{
  if (!mod.current){
    return;
  }
  // console.log(`module.current.achievements.push(${JSON.stringify(achievement)})`);
  Vue.set(mod.current.achievements, achievement._id, achievement.time);
});
client.native.users.on('user', user =>{
  if (!mod.current){
    return;
  }
  mod.insert([user]);
});
export default mod;