import { Component, Vue } from 'vue-property-decorator';
import EventCard from '@/components/EventCard.vue';
import CalendarDate from '@/components/CalendarDate.vue';
import events, {SpacewalkEvent} from '../state/events';

import {client} from '@/plugins/spacewalk';
import HostEventButton from '@/components/HostEventButton.vue';

interface Day {
  date: string;
  events: SpacewalkEvent[];
}

function sortEvents(a: SpacewalkEvent, b: SpacewalkEvent): number {
    if (a.createdBy === client.reactive.auth._id && b.createdBy !== client.reactive.auth._id){
      return -1;
    }
    if (a.createdBy !== client.reactive.auth._id && b.createdBy === client.reactive.auth._id){
      return 1;
    }
    return 0;
}
@Component({
  components: {
    CalendarDate,
    EventCard,
    HostEventButton,
  },
})
export default class Events extends Vue {
  filter: string | null = '';
  mounted(){
    events.refresh();
  }
  get events(){
    return events.all.sort(sortEvents);
  }
  get ongoing(){
    console.log('ongoing: '+events.all.length);
    return events.all.filter(event => !event.deletedAt).sort(sortEvents);
  }
  get days(){
    const days: Day[] = [];
    for (const event of events.all){
      if (!event.startsAt){
        continue;
      }
      const date = event.startsAt.substr(0, 10);
      let day = days.find(day => day.date === date);
      if (!day){
        day = {
          date,
          events: [],
        };
        days.push(day);
      }
      day.events.push(event);
    }
    days.sort((a, b) => a.date.localeCompare(b.date));
    return days;
  }
}